//@@@ https://mui.com/material-ui/react-stepper/#text-with-carousel-effect @@@@
import {imgHttpFolder} from '../../redux/globalVariable'
import React from 'react';
import AboutCarousel from './AboutCarousel'

const About = () => {
console.log(`${imgHttpFolder}/about/01.jpeg`)

return (
  <>
    <div className="service__maincontent-wrap">
      <div className="service__maincontent--width">
        <p className="home-intro">
        The Xtal Ball entity has been created for development and manufacturing of extraordinary Frequency Control and Timing products combining very high precision and accuracy with small sizes and extremely low power consumption. 
        </p>

        <div className="hello-article">
          <h4 className="hello-article__title">
          Precision matters{" "}
            <span></span>
          </h4>

          <p className="about-article__content">
          Production facilities of Xtal Ball allow large scale manufacturing the crystal resonators, OCXOs and Timing modules from the crystal blank stage. Usage of automatic test lines  incorporating in unique information system providing full transparency of the products evolution from design documentation through all manufacting procedures till delivery to the customer.
          </p>

          <AboutCarousel />

          <p className="about-article__content">
              Basing on the LP OCXOs operating at 8.192 MHz or 16.384 MHz
              frequency these clocks ensure very high precision of 1 s time
              intervals at very low power consumption and small sizes. Owing to
              the very attractive combination of properties these clocks are now
              very demanded part of different modern equipment with battery
              supply, for instance, of the underwater geological exploration
              equipment providing its long service-free period at high precision
              of the geological data.
            </p>

        </div>

      </div>
    </div>
  </>
);
}

export default About;
