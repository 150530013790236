//**** Home.jsx  */
import React from "react";

import { NavLink } from "react-router-dom";
import { useEffect } from 'react';

const Home = () => {

  useEffect(() => {
    document.title = 'Xtal Ball Technologies';
  }, []);

  const params_arr = [
    //{name: 'Frequency range', value: '8-140 MHz'},
    { name: 'Power consumption at 25ºC', value: '< 100 mW' },
    { name: 'Aging per month (typ)', value: '< 1 ppb' },
    //{name: 'Aging per month (typ) after 30 days operation', value: '>1 ppb'},
    { name: 'Allan deviation', value: 'to 5E-13/1s' },
    { name: 'Stability in (-40 +80)ºC range', value: 'to 0.4 ppb' },
    /*     {name: 'Warm up time to 0.1 ppm', value: '<60 s'},
        {name: 'Acceleration sensitivity', value: '>0.2 ppb/g'},
        {name: 'Shocks resistance', value: '>1000 g'},
        {name: 'Volume', value: '8 ccm'},
        {name: 'Weight', value: '8 gram'}, */
  ]
  return (
    <>

      <div className="service__maincontent-wrap">
        <div className="service__maincontent--width">
          <article>
            <h1 className="text-2xl text-center text-cyan-800"> OCXO Products with High Stability and Low Consumption</h1>
            <p className="home-intro">
              Xtal Ball presents a new generation of the frequency control and
              timing products possessing extraordinary combination of high
              precision performances with small sizes and extremely low power
              consumption.
            </p>


            <section className="m-[1rem] ">
              <h2
                className="text-lg sm:text-2xl bg-gradient-to-r from-amber-100 via-amber-200 bg-amber-100 text-center text-rose-500 "
              >Your Highest Precision at Lowest Power</h2>

              <div className="flex justify-center">
                <table className="table max-w-2xl" itemscope itemtype="http://schema.org/PropertyValue">

                  <tbody className="">
                    {params_arr.map((v, index) => (
                      <tr key={'gg_' + index}>
                        <td itemprop="parameter" className="text-cyan-700">{v.name}</td>
                        <td itemprop="tableCell" itemscope itemtype="http://schema.org/QuantitativeValue" className="text-lime-600">
                          <span itemprop="value">{v.value}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>


              </div>

              <footer className="m-3">
                <img className="border rounded-lg"
                  src="./img_hero/m95d.jpg"
                  alt="Perfect OCXO"
                />
              </footer>
            </section>
          </article>




          <article className="hello-article">
            <h2 className="hello-article__title">
              Low-Power High-Stability Miniature OCXOs
            </h2>
            <aside className="hello-article__collage2">
              <div className="collage__circle2">
                {" "}
                <img src="./pimages/types200/type1_200px.png" alt="OCXO type1" />
              </div>
              <div className="collage__circle2">
                {" "}
                <img src="./pimages/types200/type2_200px.png" alt="OCXO type2" />
              </div>
              <div className="collage__circle2">
                {" "}
                <img src="./pimages/types200/type4_200px.png" alt="OCXO type4" />
              </div>
              <div className="collage__circle2">
                {" "}
                <img src="./pimages/types200/type5_200px.png" alt="OCXO type5" />
              </div>
            </aside>

            <p className="hello-article__content">
              L
              <span>ow Power Oven Control Crystal Oscillators (LP OCXOs).</span>{" "}
              These extraordinary frequency references are built on the
              Internally Heated Resonators (IHR) integrating in the vacuum
              holder the crystal plate with miniature heating structure. Recent
              achievements in the IHR technology and usage of sophisticated
              oscillator circuitry provide to the OCXOs as high as{" "}
              <span>0.005 ppb/˚C</span> frequency stability, as low as{" "}
              <span>0.05 ppb/day</span> aging, less than <span>150 mW</span>{" "}
              consumption, very low phase-noise level and{" "}
              <span>DIP8 or DIP14</span> compatible sizes. Owing to the
              extraordinary performances the LP OCXOs have become unreplaceable
              part of a variety of portable or/and battery fed equipment such as
              high-end mobile radio, mobile radars, synthesizers, precision
              clocks, GPS navigation, portable instrumentation and others.
            </p>

            <footer className="hello-article__more border p-1 border-amber-600 rounded-lg hover:bg-amber-100">
              <NavLink to="/osho_models">Select your OCXO model</NavLink>
              {/* <a href="#">Select your OCXO model</a> */}
            </footer>
          </article>

          <article className="hello-article">
            <h2 className="hello-article__title">
              High Precision Low Power Master Clock
            </h2>
            <aside className="hello-article__collage">
              <div className="collage__circle--large">
                {" "}
                <img
                  src="./pimages/types200/type11_200px.png"
                  alt="Real-Time Clock/Calendar"
                />
              </div>
            </aside>
            <p className="hello-article__content">
              Using unique IHR technology there clocks ensure very high precision of 1 s time
              intervals at very low power consumption and small sizes. Owing to
              the very attractive combination of properties these clocks are now
              very demanded part of different modern equipment with battery
              supply, for instance, of the underwater geological exploration
              equipment providing its long service-free period at high precision
              of the geological data.
            </p>
            <footer className="hello-article__more border p-1 border-amber-600 rounded-lg hover:bg-amber-100">
              <NavLink to="./xbpc">Load spec example to edit and order</NavLink>
            </footer>
          </article>

          <article className="hello-article">
            <h2 className="hello-article__title">
              Ultra stable Low Noise Low Power Frequency Reference{" "}
            </h2>
            <aside className="hello-article__collage">
              <div className="collage__circle--large">
                {" "}
                <img
                  src="./pimages/types200/type12_200px.png"
                  alt="Real-Time Clock/Calendar"
                />
              </div>
            </aside>
            <p className="hello-article__content">
              These miniature frequency standards using high stability LP OCXO
              together with PLL discipline option from external GPS or PSS
              signals provide very high short-term and long-term stability
              reaching the “atomic” rank of 0.03 ppb/day during long hold-over
              period. Owing to these excellent performances the references can
              be successfully used at high-end portable instrumentation and
              other modern high precision battery supply equipment.
            </p>
            <footer className="hello-article__more border p-1 border-amber-600 rounded-lg hover:bg-amber-100">
              <NavLink to="./xbftr">Load spec example to edit and order</NavLink>
            </footer>
          </article>


          <article className="hello-article">

            <div className="max-w-screen-md mx-auto text-center p-3">
              {/*                 <svg className="w-10 h-10 mx-auto mb-3 text-gray-400 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
                </svg> */}
              <aside class="flex items-center mb-4 text-yellow-300">
                <svg class="w-5 h-5 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg class="w-5 h-5 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg class="w-5 h-5 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg class="w-5 h-5 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                  <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                </svg>
              </aside>

                <h2 className="text-2xl italic font-medium text-cyan-900 dark:text-white">
                  "Novel IHR OCXOs as Ultra-Stable Low-Power Frequency and Time References."
                </h2>
                <p className="text-left">
                  Article reviews recent achievement in development of the low power OCXOs technology based on the concept of internally heated resonators (IHR). The exhibited results prove the developed designs are perfect frequency control and timing solutions for a plenty of up-to-date electronic equipment especially with battery supply.
                </p>


              <footer className="flex items-center justify-center mt-6 space-x-3 rtl:space-x-reverse">
                <img className="w-6 h-6 rounded-full" src="./img_hero/avatar_01b.png" alt="profile picture" />
                <div className="flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-500 dark:divide-gray-700">
                  <cite className="pe-3 font-medium text-gray-900 dark:text-white">Dr. Igor Abramson</cite>
                  <cite className="ps-3 text-sm text-gray-500 dark:text-gray-400">Founder</cite>
                </div>
              </footer>

            </div>

            <footer className="hello-article__more border p-1 border-amber-600 rounded-lg hover:bg-amber-100">
              <a href="https://blog.xtalball.com/ocxo-comprehensive-article-on-observations/"><button>read article</button></a>
            </footer>
          </article>

          <article className="hello-article">
            <h2 className="hello-article__title">
              XtalBall News, Knowlege base{" "}
            </h2>

            <div className="hello-article-hero2">
              <aside className="hello-article-hero__container2">
                <div className="hello-article-hero__image2">
                  <img
                    src="./img_hero/news1a.jpg"
                    alt="Reading News"
                  />
                  {/* <span>NEWS</span> */}
                </div>
              </aside>


              <div className="hello-article-hero__right-part2">
                <div className="hello-article-hero__right-part2__list">
                  <ul className="list-disc">
                    <li> XtalBall news</li>
                    <li> Articles</li>
                    <li> Quartz theory</li>
                    <li> Generators details</li>
                    <li> Timing solutions</li>
                    <li> Theoretical issues</li>
                  </ul>
                </div>

                {/*                 <div className="hello-article-hero__right-button">
                <a href="https://blog.xtalball.com"><button>Click to follow</button></a>
                </div> */}
                <footer className="hello-article__more border mx-[2rem] border-amber-600 rounded-lg hover:bg-amber-100">
                  <a href="https://blog.xtalball.com"><button>you're welcome</button></a>
                </footer>

              </div>

            </div>



          </article>


          {/*           <div className="hello-article">
            <h4 className="hello-article__title">
              XtalBall News, Knowlege base{" "}
            </h4>

            <div className="hello-article-hero">
              <div className="hello-article-hero__left_part">
                <div className="hello-article-hero__image">
              <img
                  src="./img_hero/news1.jpg"
                  alt="Reading News"
                />
                <span>NEWS</span>
                </div>

              </div>
              <div className="hello-article-hero__right-part">
                <ul>
                  <li> XtalBall news</li>
                  <li> Articles</li>
                  <li> Quartz theory</li>
                  <li> Generators details</li>
                  <li> Timing solutions</li>
                  <li> Theoretical issues</li>

                </ul>
                
                <div className="hello-article-hero__right-button">
                <a href="https://blog.xtalball.com"><button>Click to follow</button></a>
                </div>

              </div>
 
            </div>


          </div> */}

        </div>
      </div>
    </>
  );
};

export default Home;
