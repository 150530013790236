//***********  SpecificationBannerStep1.jsx **** */
import React from "react";
import "./SpecificationBannerStep1.scss"
import {imgPathClockTimerUsfr22} from '../../redux/globalVariable'

const SpecificationTimerXBFTR = (props) => {
  

let myArray = []
const extractFeatures = (a) => {
  //window.bc = myArray
  if (a) {
    return a.map((x, index) => {
      return <h5 key={index}>{x}</h5>;
    })
  }
}
//window.bb = extractFeatures(props.features)

      return (
        

        <div className="speciication__wrap--position">
              <div className="speciication-model__head-title">
              High Precision Low Noise Low Power Time and Frequency Reference
              </div>
          <div className="speciication-selected-banner_wrap--decor">
            
            <div className="speciication-model__title-name">
              <h1>XBFTR</h1>
            </div>
            <div className="speciication-model__title-frequency">
              <p></p>
            </div>
            <div className="speciication-model__info-wrap">
              <div className="speciication-model__picture">
                <img src='./pimages/types200/type12_200px.png' />
              </div>
              <div className="speciication-model__package">
                <span>Packaging: </span>25.4x37.24x10mm
              </div>

              <div className="speciication-model__features"></div>
            </div>
          </div>

          <div className="service__maincontent-wrap">
            <div className="service__maincontent--width">
              <div className="hello-article">
            <div className="timer-features__list">

                              
<h5 id="brief_specification">Brief Specification</h5>
<div className="level5">
<ul>
<li className="level1 node"><div className="li"> Output Frequencies</div>

<ul>
<li className="level2"><div className="li">1PPS</div>
</li>
<li className="level2"><div className="li"> 10 <abbr title="Megahertz">MHz</abbr>{" "}(sine wave / HCMOS) </div>
</li>
<li className="level2"><div className="li"> (Other frequencies are available)</div>
</li>
</ul>
</li>

</ul>

</div>

<h5 id="free_running_stability_10mhz">Free running stability 10MHz:</h5>
<div className="level5">
<ul>
<li className="level1"><div className="li"> Holdover</div>
</li>
<li className="level1 node"><div className="li"> 0..+50°C:</div>
<ul>
<li className="level2"><div className="li"> 1.1 uS for 1 hour</div>
</li>
<li className="level2"><div className="li"> 31 uS for 24 hours</div>
</li>
</ul>
</li>
<li className="level1 node"><div className="li"> -40..+85°C:</div>
  <ul>
  <li className="level2"><div className="li"> 2 uS for 1 hour</div>
  </li>
  <li className="level2"><div className="li"> 48 uS for 24 hours</div>
  </li>
  </ul>
</li>

<li className="level1 node"><div className="li"> Aging:</div>
<ul>
  <li className="level2"><div className="li"> 0.1 ppb / day</div>
  </li>
  <li className="level2"><div className="li"> 20  ppb / year</div>
  </li>
</ul>
</li>

<li className="level1 node"><div className="li"> Temperature stability:</div>
<ul>
  <li className="level2"><div className="li"> 0..+50°C:	 0.3 ppb</div>
  </li>
  <li className="level2"><div className="li"> -40..+85°C:	 0.5 ppb</div>
  </li>
</ul>
</li>

<li className="level1 node"><div className="li"> Phase Noise (Typ):</div>
<ul>
<li className="level2"><div className="li"> -105dBc/Hz at 1Hz</div>
</li>
<li className="level2"><div className="li"> -137dBc/Hz at 10Hz</div>
</li>
<li className="level2"><div className="li"> -160dBc/Hz at 100Hz</div>
</li>
<li className="level2"><div className="li"> -165dBc/Hz at 1kHz</div>
</li>
<li className="level2"><div className="li"> -168dBc/Hz at 10kHz</div>
</li>
</ul>
</li>

<li className="level1 node"><div className="li"> Allan Deviation (Typ):</div>
<ul>
  <li className="level2"><div className="li"> 3e-12 /1s</div>
  </li>
  <li className="level2"><div className="li"> 14e-12 /10s</div>
  </li>
  <li className="level2"><div className="li"> 120e-12 /100s</div>
  </li>
</ul>
</li>

<li className="level1 node"><div className="li"> Frequency adjust:</div>
<ul>
  <li className="level2"><div className="li"> ±0.3ppm (Typ) by DC voltage at FC pin and
	by 16bit I2C DAC with EEPROM capability </div>
  </li>
  <li className="level2"><div className="li"> (16bit corresponds 15ppt (Typ) resolution)</div>
  </li>
</ul>
</li>

</ul>

</div>

<h5 id="power_supply">Power Supply:</h5>
<div className="level5">
<ul>
<li className="level1"><div className="li"> Voltage: 5 V (3.3V optional)</div>
</li>
<li className="level1 node"><div className="li"> Consumption:</div>
<ul>
<li className="level2"><div className="li">&lt;125mW at 25°C for -0..+50°C working range</div>
</li>
<li className="level2"><div className="li"> &lt;175mW at 25°C for -40..+85°C working range</div>
</li>
</ul>
</li>
</ul>

</div>



                <h5 id="power_supply">Dimension Connections</h5>
                <div className="level-img__wrap">
                <img className="level-img" src="./pimages/spec_case_pinout/XBTFRx600px.png" alt="rtcc22-bdiagram" /> 
                </div>
            </div>    

              </div>
            </div>
          </div>
        </div>
      );
  }


export default SpecificationTimerXBFTR