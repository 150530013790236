//***********  SpecificationBannerStep1.jsx **** */
import React from "react";
import "./SpecificationBannerStep1.scss"
//import {imgPathClockTimerRtcc22} from './pimages/types200/type6_200px.png'

const SpecificationTimerXbpc = (props) => {
  

let myArray = []
const extractFeatures = (a) => {
  //window.bc = myArray
  if (a) {
    return a.map((x, index) => {
      return <h5 key={index}>{x}</h5>;
    })
  }
}
//window.bb = extractFeatures(props.features)

      return (
        <div className="speciication__wrap--position">
              <div className="speciication-model__head-title">
              High Precision Low Power Real-Time Clock/Calendar
              </div>
          <div className="speciication-selected-banner_wrap--decor">
            <div className="speciication-model__title-name">
              <h1>XBPC-08S</h1>
            </div>
            <div className="speciication-model__title-frequency">
              <p></p>
            </div>
            <div className="speciication-model__info-wrap">
              <div className="speciication-model__picture">
                <img src='./pimages/types200/type11_200px.png' />
              </div>
              <div className="speciication-model__package">
                <span>Packaging: </span>25.4x30.04x9.5mm
              </div>

              <div className="speciication-model__features"></div>
            </div>
          </div>

          <div className="service__maincontent-wrap">
            <div className="service__maincontent--width">
              <div className="hello-article">
            <div className="timer-features__list">

            <h5 id="mhz_32768_khz_outputs">Brief Specification:</h5>
                <div className="level5">
                <ul>
                <li className="level1 node"><div className="li"> Output frequency:</div>
                  <ul>
                    <li className="level2">
                      <div className="li"> 1 sec period 20 ms duration clock impulse</div>
                    </li>
                  </ul>
                </li>
                </ul>

                <ul>
                <li className="level1 node"><div className="li"> Low Time Jitter:</div>
                <ul>
                <li className="level2"><div className="li"> 6ps/1s 0.4ns/10s</div>
                </li>
                </ul>
                </li>
                <li className="level1 node"><div className="li"> Timekeeping/Holdover for time after calibration</div>
                <ul>
                <li className="level2 node"><div className="li"> 0..+50°C:</div>
                <ul>
                <li className="level3"><div className="li"> 5 uS for 1 hour</div>
                </li>
                <li className="level3"><div className="li"> 125 uS for 24 hours</div>
                </li>
                <li className="level3"><div className="li"> 15 mS for 30 days</div>
                </li>
                <li className="level3"><div className="li"> 0.5 S for 1year</div>
                </li>
                </ul>
                </li>
                <li className="level2 node"><div className="li"> -40..+85°C:</div>
                <ul>
                <li className="level3"><div className="li"> 9.3 uS for 1 hour</div>
                </li>
                <li className="level3"><div className="li"> 244 uS for 24 hours</div>
                </li>
                <li className="level3"><div className="li"> 26 mS for 30 days</div>
                </li>
                <li className="level3"><div className="li"> 1 S for 1 year</div>
                </li>
                </ul>
                </li>
                </ul>
                </li>

                </ul>

                </div>


                <h5 id="power_supply">Power Supply:</h5>
                <div className="level5">
                <ul>
                <li className="level1"><div className="li"> Voltage 3.3 or 5 V</div>
                </li>
                <li className="level1"><div className="li"> Consumption 90 mW ( 0..+50°C ) 140 mW ( -40..+85°C )</div>
                </li>
                </ul>

                </div>

                <h5 id="power_supply">Dimension Connections</h5>
                <div className="level-img__wrap">
                <img className="level-img" src="./pimages/spec_case_pinout/XBPC_9_5x600.png" alt="XBPC_9_5-bdiagram" /> 
            </div>    
            </div>
              </div>
            </div>
          </div>
        </div>
      );
  }


export default SpecificationTimerXbpc