import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";


const HeaderMenu = (props) => {

  const [isChecked, setIsChecked] = useState(false);

  return (
    <nav className="top-nav">

    <NavLink style={{ textDecoration: 'none',}}  to="./">
      <div className="top-nav-logo-name">
      
        <img
          src={require("../../../assets/img/nav-logo.png")}
          alt=""
          className="top-nav-logo"
        />

        <div className="top-nav-name_v2">
          <div className="top-nav-name_v2__bold">XTAL BALL</div>
          <div className="top-nav-name_v2__ordinary">TECHNOLOGIES</div>
          </div>
      </div>
      
      </NavLink>

      <input id="menu-toggle" type="checkbox" 
        checked={isChecked}
        onChange={() => setIsChecked((prev) => !prev)}
        />
      <label className="menu-button-container" htmlFor="menu-toggle">
        <div className="menu-button"></div>
      </label>
      <ul className="menu" onClick={() => setIsChecked((prev) => !prev)} >
      <li>
          <NavLink to="./">Home</NavLink>
        </li>
        <li>
          <NavLink to="./osho_models">OCXO Models</NavLink>
        </li> 
        <li>
          <NavLink to="./rtcc22">High Precision Low Power Clocks.</NavLink>
        </li>     
        <li>
        <NavLink to="./usfr22">Disciplined Low Power Frequency Reference.</NavLink>
        </li>
        <li>
          <a href="https://blog.xtalball.com"> News, articles</a>
        </li>
        <li>
          <NavLink to="./about">About Us</NavLink>
        </li>  
        {/* <li>Contacts</li> */}
      </ul>
    </nav>
  );
};

export default HeaderMenu;
